<template>
  <v-container>
    <!-- <v-row>
      <v-col cols="12">
        <h2 class="font-weight-regular text-center">Notifications</h2>
      </v-col>
      <v-col cols="6">
        <v-card
          class="pa-6 mt-10 mx-auto"
          elevation="8"
          max-width="calc(100% - 32px)"
          color="pink"
        >
          <v-card-text>
            <div class="amber--text">Notification</div>
            <p class="display-1 white--text">
              COVID-19 Update
            </p>

            <p class="white--text">
              Please note that the Work form Home stratergy will be implemented
              from 20/05/2021 onwards. Please submit your work plan on or before
              18/05/2021
            </p>
            <p class="white--text mt-6">Director/ NIFS</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="2" class="pa-6 mt-10">
          <h2 class="font-weight-regular mb-4">Communicate new message</h2>
          <v-form v-model="valid" ref="form" @submit.prevent="onSubmit">
            <v-select
              v-model="priority"
              :items="priorities"
              label="Priority"
              dense
              outlined
              required
              :rules="textRules"
            ></v-select>
            <v-autocomplete
              v-model="recipient"
              :items="recipients"
              outlined
              dense
              chips
              small-chips
              label="Recipients"
              multiple
            ></v-autocomplete>
            <v-text-field
              v-model="subject"
              label="Subject"
              dense
              outlined
              required
              :rules="textRules"
            ></v-text-field>
            <v-textarea
              counter
              v-model="message"
              label="Message"
              dense
              outlined
              required
              :rules="messageRules"
            ></v-textarea>

            <v-btn
              class="ma-2 ml-0"
              outlined
              color="indigo"
              :disabled="!valid"
              @click="submit"
            >
              Send
            </v-btn>
            <v-btn class="ma-2" outlined color="red" @click="reset">
              Clear
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      textRules: [(v) => !!v || "This field is required"],
      messageRules: [
        (v) => v.length <= 200 || "Max 200 characters",
        (v) => !!v || "This field is required",
      ],
      priorities: ["Normal", "Urgent"],
      recipients: [
        "Science Education and Dissemination Unit",
        "Accounts Division",
      ],
    };
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
